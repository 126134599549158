<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('apps-clients-list')"
        @delete="helperDeleteItem(`/clients/${$route.params.clientid}`, null, 'apps-clients-list')"
        @save="updateItem"
        @refresh="getItem"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
        :title="currentItem.name"
    >
    </b-card-actions>

    <single-image
        :card-title="$t('Logo upload')"
        :current-item="currentItem"
        :upload-url="logoUploadUrl"
        :delete-url="logoDeleteUrl"
        :image-url.sync="currentItem.logo.url"
    >
    </single-image>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(updateItem)" @reset.prevent="resetForm">
        <b-card>
          <!-- Status -->
          <div class="mb-2">
            <b-form-checkbox v-model="currentItem.status" value="1" name="status" class="custom-control-success">
              {{ $t('Active') }}
            </b-form-checkbox>
          </div>

          <b-row>
            <b-col md="6">
              <!-- Name -->
              <validation-provider #default="validationContext" :name="$t('Client name')" rules="required">
                <b-form-group :label="$t('Client name')">
                  <b-form-input v-model="currentItem.name" trim :placeholder="$t('Client name')"/>

                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger">{{
                      $t(validation.name[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="validationContext" :name="$t('Domain')" rules="required">
                <b-form-group :label="$t('Domain')">
                  <b-form-input v-model="currentItem.subdomain" trim :placeholder="$t('Domain')"/>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('subdomain')" class="text-danger">{{
                      $t(validation.subdomain[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>


          <hr>
          <h4>{{ $t('Fee') }}</h4>
          <b-row>
            <b-col md="6">
              <validation-provider #default="validationContext" :name="$t('Fee')" rules="required">
                <b-form-group :label="$t('percentage fee')">
                  <b-input-group append="%">
                    <b-form-input v-model="currentItem.fee" trim :placeholder="$t('percentage fee')"/>
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
                <small v-if="validate && Object.keys(validation).includes('fee')" class="text-danger">{{
                    $t(validation.fee[0])
                  }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('Affiliate User')">
                <b-form-select v-model="currentItem.affiliate_user" :label="$t('Affiliate User')" :options="affiliateUsers"
                               value-field="id" text-field="name"
                >
                </b-form-select>
              </b-form-group>

            </b-col>
          </b-row>
        </b-card>
        <b-row>
          <b-col md="4">
            <b-card :title="$t('Custom sidebar styles')" class="h-100">
              <b-form-group :label="$t('Background color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.background"/>
              </b-form-group>

              <b-form-group :label="$t('Text color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.textcolor"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item background color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.background"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item text color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.textcolor"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item active text color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.active.textcolor"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item active background color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.active.background"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item hover text color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.hover.textcolor"/>
              </b-form-group>

              <b-form-group :label="$t('Nav-Item hover background color')">
                <b-form-input type="color" v-model="currentItem.colors.sidebar.navitem.hover.background"/>
              </b-form-group>

              <b-button @click="resetColors" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" class="mt-2">
                {{ $t('Reset colors to default') }}
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card :title="$t('Custom topbar styles')" class="h-100">
              <b-form-group :label="$t('Background color')">
                <b-form-input type="color" v-model="currentItem.colors.topbar.background"/>
              </b-form-group>

              <b-form-group :label="$t('Text color')">
                <b-form-input type="color" v-model="currentItem.colors.topbar.textcolor"/>
              </b-form-group>


              <b-form-group :label="$t('Link color')">
                <b-form-input type="color" v-model="currentItem.colors.topbar.link.color"/>
              </b-form-group>


              <b-form-group :label="$t('Link hover color')">
                <b-form-input type="color" v-model="currentItem.colors.topbar.link.hover.color"/>
              </b-form-group>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card :title="$t('Custom colors & button styles')" class="h-100">
              <b-form-group :label="$t('Main color')">
                <b-form-input id="primarycolor" type="color" v-model="currentItem.colors.primarycolor"/>
              </b-form-group>

              <b-form-group :label="$t('Secondary Color')">
                <b-form-input id="secondarycolor" type="color" v-model="currentItem.colors.secondarycolor"/>
              </b-form-group>

              <b-form-group :label="$t('Success color')">
                <b-form-input type="color" v-model="currentItem.colors.button.success"/>
              </b-form-group>

              <b-form-group :label="$t('Danger color')">
                <b-form-input type="color" v-model="currentItem.colors.button.danger"/>
              </b-form-group>


              <b-form-group :label="$t('Info color')">
                <b-form-input type="color" v-model="currentItem.colors.button.info"/>
              </b-form-group>


              <b-form-group :label="$t('Warning color')">
                <b-form-input type="color" v-model="currentItem.colors.button.warning"/>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
        <b-card class="mt-2">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
            {{ $t('Update') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BInputGroupAppend,
  BInputGroup,
  BMedia,
  BAvatar,
  BFormSelect

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { required, alphaNum, email } from '@validations'
import SingleImage from '@/views/components/SingleImage'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    BMedia,
    BAvatar,
    BFormSelect,
    SingleImage,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
//currentItem.colors.sidebar.background
  data() {
    return {
      currentItem: {
        id: '',
        name: '',
        status: '',
        subdomain: '',
        affiliate_userL: '',
        primarycolor: '',
        secondarycolor: '',
        logo: {},
        colors: {
          topbar: {
            link: {
              hover: {}
            }
          },
          sidebar: {
            background: '',
            navitem: {
              background: '#ff0000',
              active: {},
              hover: {}
            }
          },
          button: {
            success: '',
            danger: '',
            info: '',
            warning: ''
          }
        },
      },
      defaultColors: {
        primarycolor: '#207776',
        secondarycolor: '#a47e4e',
        topbar: {
          link: {
            hover: {}
          }
        },
        sidebar: {
          background: '#000000',
          textcolor: '#ffffff',
          navitem: {
            textcolor: '#ffffff',
            background: '#000000',
            active: {
              textcolor: '#ffffff',
              background: '#a47e4e'
            },
            hover: {
              textcolor: '#ffffff',
              background: '#a47e4e',
            }
          }
        },
        button: {
          success: '#207776',
          danger: '#d11e21',
          info: '#026666',
          warning: '#a47e4e'
        }
      },
      loaded: true,
      validation: {},
      validate: false,
      affiliateUsers: [],
      logoUploadUrl: `/clients/logoupload/${this.$route.params.clientid}`,
      logoDeleteUrl: `/clients/logoremove/${this.$route.params.clientid}`,
    }
  },

  methods: {
    getItem() {
      this.helperGetItem(`/clients/${this.$route.params.clientid}`)
    },
    resetColors() {
      this.currentItem.colors = this.defaultColors
    },
    updateItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperUpdateItem(
                  `/clients/${this.$route.params.clientid}`,
                  this.currentItem
              )
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    getAffilateUser() {
      this.$http.get('/affiliates')
          .then(response => {
            this.affiliateUsers = response.data.items
          })
    }
  },
  mounted() {
    this.getItem()
    this.getAffilateUser()
  },
  beforeMount() {
    this.addBreadcrumbDataFromRouteParams(this.$route.params)
  }
}
</script>

<style>
</style>
